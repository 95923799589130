@font-face {
    font-family: 'Hero';
    src: url('../fonts/Hero-Regular.woff2') format('woff2'),
        url('../fonts/Hero-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hero';
    src: url('../fonts/Hero-Bold.woff2') format('woff2'),
        url('../fonts/Hero-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hero';
    src: url('../fonts/Hero-Light.woff2') format('woff2'),
        url('../fonts/Hero-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
