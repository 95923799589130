
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~bootstrap/scss/bootstrap";
@import "fonts";

body {
  font-family: 'Hero', sans-serif;
  // background: black;
}

.color-input .form-label {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.watch {
  
  // svg {
  //   height: 100vh;
  // }
}